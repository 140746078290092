import { useStaticQuery, graphql } from 'gatsby';
import { Post } from 'models/post';
import { useRecoilState } from 'recoil';
import { languageAtom } from 'atoms/language';

export const useAllPosts = (ignoreLanguage = true) => {
  const [lang] = useRecoilState(languageAtom);
  const data = useStaticQuery(graphql`
    query AllPostsQuery {
      allMarkdownRemark(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { fields: { mdType: { eq: "posts" } } }
      ) {
        edges {
          node {
            ...PostQueryFragment
          }
        }
      }
    }
  `);

  const frontmatters: Post[] = data.allMarkdownRemark.edges
    .map(({ node }: { node: Post }) => node)
    .filter((p: Post) => (ignoreLanguage ? p.fields.lang === lang : true));

  return frontmatters;
};
